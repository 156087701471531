import { Component, OnInit } from '@angular/core';
import {CognitoServiceProvider} from '../auth/cognito-service';
import {Router} from '@angular/router';
import {ProgramService} from "../shared/service/program.service";
import {IProgram} from "../shared/model/program.model";


@Component({
  selector: 'app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.css']
})
export class AppLayoutComponent implements OnInit {

  program: IProgram;
  programId: number;
  constructor(private cognitoService: CognitoServiceProvider,private router:Router, private programService: ProgramService) { }
  ngOnInit() {
    this.programId = Number(localStorage.getItem('programId'));
    this.programService.find(this.programId).subscribe( res => {
      this.program = res.body;
    })
  }

  logout(){
    this.cognitoService.signOut();
    this.router.navigate(['/login']);
  }
}
