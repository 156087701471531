<div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="5px" fxLayoutAlign="center">
    <app-period-selector (selectedPeriod)="onDateChange($event)"></app-period-selector>
</div>
<div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px ">


    <div fxFlex.xl="calc(20% - 20px)" fxFlex.lg="calc(20% - 20px)" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
        <mat-card style="font-size: 14px !important; margin: 10px !important;  ">
            <div class="title">Totaal aantal activaties van de selectie</div>
            <div style=" text-align: center;">
                <span class="amount">{{total|async}}</span>
            </div>
        </mat-card>
    </div>

</div>
<div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px">
    <div fxFlex.xl="calc(100% - 20px)" fxFlex.lg="calc(100% - 20px)" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
        <mat-card style="font-size: 14px !important; margin: 10px !important;">
            <bar-chart-vert [title]="'Activering per dag'" [result]="result" [legend]="true" *ngIf="result.length > 0">
            </bar-chart-vert>
            <br><br>
        </mat-card>
    </div>
</div>