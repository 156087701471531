import {IProvisioningBudget} from './provisioning-budget.model';
import {IPartProvBudgetWallet} from './part-prov-budget-wallet.model';
import {ITransaction} from './transaction.model';
import {ITransactionRow} from './transaction-row.model';

export interface IProvisioning {
  id?: number;
  name?: string;
  provisioningBudgets?: IProvisioningBudget[];

  programId?: number;

}
export class Provisioning implements IProvisioning {
  constructor(public id?: number, public name?: string, public provisioningBudgets?: IProvisioningBudget[],
              public programId?: number) {}
}
