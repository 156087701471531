import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import * as moment from 'moment';

import {map} from 'rxjs/internal/operators';
import {createRequestOption} from '../util/util/request-util';
import {environment} from '../../../environments/environment';
import {IProgram} from "../model/program.model";

type EntityResponseType = HttpResponse<IProgram>;
type EntityArrayResponseType = HttpResponse<IProgram[]>;

@Injectable({providedIn: 'root'})
export class ProgramService {
  public resourceUrl = environment.SERVER_API_URL + 'api/programs';

  constructor(protected http: HttpClient) {
  }


  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<IProgram>(`${this.resourceUrl}/${id}`, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }


  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      // res.body.start = res.body.start != null ? moment(res.body.start) : null;
      // res.body.end = res.body.end != null ? moment(res.body.end) : null;
    }
    return res;
  }

}
