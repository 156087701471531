import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DateHelper } from 'src/app/shared/util/util/date-helper';
import { Period } from './period.model';


@Component({
  selector: 'app-period-selector',
  templateUrl: './period-selector.component.html',
  styleUrls: ['./period-selector.component.scss']
})



export class PeriodSelectorComponent implements OnInit {
//  @Output() selectedStartDate = new EventEmitter<Date>();
//  @Output() selectedEndDate = new EventEmitter<Date>();
  @Output() selectedPeriod = new EventEmitter<Period>();
  startDate: Date;
  endDate: Date;
  periodHolder:Period;
  today = new Date();

  


  constructor() { }

  ngOnInit(): void {
    this.periodHolder = new Period();
    this.periodHolder.startDate = this.initDate('startDate',30);
    this.periodHolder.endDate = this.initDate('endDate',0);
    this.selectedPeriod.emit(this.periodHolder);

  }

  initDate(dateKey: string, defaultMinusDays: number):Date {
    var date:Date;
    let tempDate= sessionStorage.getItem(dateKey);
    if ( tempDate ) {
      date = new Date(tempDate);
    }
    else {
      date = new Date();
      date = DateHelper.minusDays(date,defaultMinusDays);
    }
    return date

  }

  onStartDateChange() {
    sessionStorage.setItem('startDate', this.periodHolder.startDate.toISOString());  
    if ( this.periodHolder.startDate > this.periodHolder.endDate) {
      this.periodHolder.endDate = this.periodHolder.startDate;
      sessionStorage.setItem('endDate', this.periodHolder.endDate.toISOString());
    }  
    this.selectedPeriod.emit(this.periodHolder);
  }

  onEndDateChange() {
    sessionStorage.setItem('endDate', this.periodHolder.endDate.toISOString());
    if ( this.periodHolder.endDate < this.periodHolder.startDate) {
      this.periodHolder.startDate = this.periodHolder.endDate;
      sessionStorage.setItem('endDate', this.periodHolder.endDate.toISOString());
    } 
    this.selectedPeriod.emit(this.periodHolder);
  }

}
