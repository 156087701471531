<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<div class="topnav">
  <a href="#LOGO"><img src="assets/images/logo_small.png" alt="please wait image is loading" width="80px" height="100px" class="logo center"></a>
  <span class="title" *ngIf="program">{{program?.name}}</span>

  <div class="topnav-right">
    <span  (click)="logout()">Uitloggen</span>
  </div>
</div>

<div fxLayout="row wrap"
     fxLayoutGap="20px"
     fxLayoutAlign="space-between start" class="layout">

  <div fxFlex.xl="calc(100% - 20px)"
       fxFlex.lg="calc(100% - 20px)"
       fxFlex.md="100"
       fxFlex.sm="100"
       fxFlex.xs="100">
    <app-dashboard-provisioning></app-dashboard-provisioning>
  </div>
</div>
