<div style="display: block;" *ngIf="ready">
  <canvas baseChart height="75"
          [datasets]="barChartData"
          [labels]="barChartLabels"
          [options]="barChartOptions"
          [colors]="barChartColors"
          [plugins]="barChartPlugins"
          [legend]="barChartLegend"
          [chartType]="barChartType">
  </canvas>
</div>

