import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router
} from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
    ) {}
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        if (localStorage.getItem('accessToken') != null) {
            const key = 'roles';
            const roles = next.data[key] as Array<string>;
            if (roles) {
              return true;
            } else {
                return true;
            }
        }
        this.router.navigate(['/login']);
        return false;
    }
}
