<div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" >
    <!-- <div fxLayout="row" fxLayoutAlign="space-evenly stretch"> -->

    <div fxFlex.xl="calc(20% - 20px)" fxFlex.lg="calc(20% - 20px)" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
        <mat-card class="card">
            <div style=" text-align: center;">
                <div class="amount"> Totaal verzilverd: {{format(totalAmount|async)}} </div>
            </div>
            <div class="pie" style="margin: 10px">
                <div style="display: block;">
                    <canvas baseChart [data]="paymentsData" [labels]="budgetLabels" [chartType]="chartType"
                        [options]="chartEuroOptions" [plugins]="chartPlugins" [colors]="chartColors" [legend]="chartLegend">
                    </canvas>
                </div>
            </div>
        </mat-card>
    </div>

    <div fxFlex.xl="calc(20% - 20px)" fxFlex.lg="calc(20% - 20px)" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
        <mat-card class="card">
            <div style=" text-align: center;">
                <div class="amount">Totaal beschikbaar op kado kaart: {{format(totalUnused|async)}} </div>
            </div>
            <div class="pie" style="margin: 10px">
                <div style="display: block;">
                    <canvas baseChart [data]="unusedData" [labels]="budgetLabels" [chartType]="chartType"
                        [options]="chartEuroOptions" [plugins]="chartPlugins" [colors]="chartColors" [legend]="chartLegend">
                    </canvas>
                </div>
            </div>
        </mat-card>
    </div>


</div>
<div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px " >
    <div fxFlex.xl="calc(20% - 20px)" fxFlex.lg="calc(20% - 20px)" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" *ngIf="budgetManagementEnabled">
        <mat-card class="card">
            <div style=" text-align: center;">
                <div class="amount">Totaal beschikbaar budget: {{format(totalAvailable|async)}} </div>
            </div>
            <div class="pie" style="margin: 10px">
                <div style="display: block;">
                    <canvas baseChart [data]="availableData" [labels]="budgetLabels" [chartType]="chartType"
                        [options]="chartEuroOptions" [plugins]="chartPlugins" [colors]="chartColors" [legend]="chartLegend">
                    </canvas>
                </div>
            </div>
        </mat-card>
    </div>
    <div fxFlex.xl="calc(20% - 20px)" fxFlex.lg="calc(20% - 20px)" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
        <mat-card class="card">

            <div style=" text-align: center;">
                <div class="amount">Aantal kado kaarten per voorziening</div>
            </div>
            <div class="pie" style="margin: 10px">
                <div style="display: block;">
                    <canvas baseChart [data]="participantCounts" [labels]="budgetLabels" [chartType]="chartType"
                        [options]="chartOptions" [plugins]="chartPlugins" [colors]="chartColors" [legend]="chartLegend">
                    </canvas>
                </div>
            </div>
        </mat-card>

    </div>
    <div fxFlex.xl="calc(20% - 20px)" fxFlex.lg="calc(20% - 20px)" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
        <mat-card class="card">
            <div style=" text-align: center;">
                <div class="amount">Totaal in wachtrij: {{totalWaiting|async}} </div>
            </div>
            <div class="pie" style="margin: 10px">
                <div style="display: block;">
                    <canvas baseChart [data]="waitingData" [labels]="budgetLabels" [chartType]="chartType"
                        [options]="chartOptions" [plugins]="chartPlugins" [colors]="chartColors" [legend]="chartLegend">
                    </canvas>
                </div>
            </div>
        </mat-card>
    </div>
</div>

