export class DateHelper {
    static addDays(date, days): Date {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    static minusDays(date, days): Date {
        var result = new Date(date);
        result.setDate(result.getDate() - days);
        return result;
    }

    static calculateDiff(start:Date, end:Date): number{
        // let date = new Date(start);
        // let currentDate = new Date();
    
        // let days = Math.floor((end.getTime() - start.getTime()) / 1000 / 60 / 60 / 24);
        return Math.floor((end.getTime() - start.getTime()) / 1000 / 60 / 60 / 24);;
      }
}