import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';

import {createRequestOption} from '../util/util/request-util';
import {environment} from '../../../environments/environment';
import {IProvisioning} from '../model/provisioning.model';

type EntityArrayResponseType = HttpResponse<IProvisioning[]>;

@Injectable({providedIn: 'root'})
export class ProvisioningService {
  public resourceUrl = environment.SERVER_API_URL + 'api/provisionings';

  constructor(protected http: HttpClient) {
  }

  findByProgramId(id: number): Observable<EntityArrayResponseType> {
    return this.http.get<IProvisioning[]>(`${this.resourceUrl}byProgramId/${id}`, {observe: 'response'});
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<IProvisioning[]>(this.resourceUrl, {params: options, observe: 'response'});
  }
}
