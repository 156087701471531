import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import * as moment from 'moment';
import {map} from 'rxjs/operators';

import {createRequestOption} from '../util/util/request-util';
import {environment} from '../../../environments/environment';
import {ITransaction} from '../model/transaction.model';
import {TransactionType} from '../model/enumaration/transaction-type.model';
import { DatePipe } from '@angular/common';

type EntityResponseType = HttpResponse<ITransaction>;
type EntityArrayResponseType = HttpResponse<ITransaction[]>;

@Injectable({providedIn: 'root'})
export class TransactionService {
  public resourceUrl = environment.SERVER_API_URL + 'api/dashboard/v1/transactions';


  constructor(protected http: HttpClient, public datepipe: DatePipe) {
  }
  transactionsByBudgetAndProviders(budgetIds: number[], providers:number[],start: Date, end: Date) {
    let params = new HttpParams();
    params = params.append("startDate", this.datepipe.transform(start, 'yyyy-MM-dd'))
                 .append("endDate", this.datepipe.transform(end, 'yyyy-MM-dd'))
                 .append("providerIds", providers.toString() )
                 .append("budgetIds", budgetIds.toString());
    return this.http.get(`${this.resourceUrl}` + '/daily-stats', {params: params, observe : 'response'});
  }


}
