<div style="display: block;">
  <canvas baseChart
          [data]="pieChartData"
          [labels]="pieChartLabels"
          [chartType]="pieChartType"
          [options]="pieChartOptions"
          [plugins]="pieChartPlugins"
          [colors]="barChartColors"
          [legend]="pieChartLegend">
  </canvas>
</div>
