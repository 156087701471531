import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AccessTokenStatsService } from 'src/app/shared/service/access-token-stats.service';
import { DateHelper } from 'src/app/shared/util/util/date-helper';
import { Period } from '../../period-selector/period.model';

@Component({
  selector: 'app-access-tokens',
  templateUrl: './access-tokens.component.html',
  styleUrls: ['./access-tokens.component.scss']
})
export class AccessTokensComponent implements OnInit {
  @Input() programId: string;
  total: BehaviorSubject<number>;
  period: Period;
  startDate: Date;
  endDate: Date;
  result = [];
  lists: any;

  constructor( public datepipe: DatePipe, private dailyStatsService: AccessTokenStatsService) { }

  ngOnInit(): void {
    this.total = new BehaviorSubject(0);
  }

  onDateChange(period:Period){
    console.log("start" +period.startDate + " end:" + period.endDate);
    this.period = period
    this.loadData();

  }

  loadData() {
    this.dailyStatsService.accessTokensDailyStats(this.programId,this.period.startDate, this.period.endDate).subscribe(res =>{
      this.lists = res.body;
      console.log(this.lists);
      this.createDatalist();
      this.totals();
    })
  }

  totals() {
    this.total.next(this.lists
      .map(token => token.count)
      .reduce((acc, qty) => acc + qty, 0));
      console.log(this.total.value);
  }


  createDatalist() {
    const newResult = [];
    var dayCounter:number;
    var totalDays:number;
    var current:Date;
    current = this.period.startDate;
    totalDays = DateHelper.calculateDiff(this.period.startDate,this.period.endDate);

    for ( dayCounter = 0; dayCounter<= totalDays; dayCounter++){
      const res = this.lists.filter(a => {

        return a.date === this.datepipe.transform(current, 'yyyy-MM-dd');
      });
      console.log(res);
      if (res.length > 0) {
        newResult.push({ label: this.datepipe.transform(current, 'yyyy-MM-dd'), qty: res[0].count, count: res[0].count });
      } else {
        newResult.push({ label: this.datepipe.transform(current, 'yyyy-MM-dd'), qty: 0, count: 0 });
      }
      // its is new day
      current = DateHelper.addDays(current,1);

    }
    this.result.length = 0;
    this.result = newResult;
  
  }

}
