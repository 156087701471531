import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {IProvisioningBudget} from '../model/provisioning-budget.model';
import { IProvider } from '../model/provider.model';

@Injectable({providedIn: 'root'})
export class ProvisioningBudgetService {
  public resourceUrl = environment.SERVER_API_URL + 'api/provisioning-budgets';

  constructor(protected http: HttpClient) {
  }

  find(id: number): Observable<HttpResponse<IProvider[]>> {
    return this.http.get<IProvider[]>(`${this.resourceUrl}/${id}/providers`, {observe: 'response'});
  }

  // findProvidersWithAnyTransaction(id: number): Observable<HttpResponse<IProvider[]>> {
  //     return this.http.get<IProvider[]>(`${this.resourceUrl}/${id}/providers-with-transaction`, {observe: 'response'});
  //   }


    
  findProvidersWithAnyTransaction(ids: number[]): Observable<HttpResponse<IProvider[]>> {
    let params = new HttpParams();
    params = params.append("budgetIds", ids.toString());
    return this.http.get<IProvider[]>(`${this.resourceUrl}/providers-with-transaction`, 
    {params: params, observe : 'response'});
  }

}
