import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AppLayoutComponent} from './layout/app-layout.component';


const routes: Routes = [


{path: 'login', loadChildren: () => import('./components/login/login.module').then(m => m.LoginModule)},
{path: 'request-password', loadChildren: () => import('./components/login/login.module').then(m => m.LoginModule)},
{path: 'confirm-password', loadChildren: () => import('./components/login/login.module').then(m => m.LoginModule)},
// App routes goes here here
{
  path: '',
    component: AppLayoutComponent,
}]

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
