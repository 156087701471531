<mat-form-field>
    <input matInput [matDatepicker]="start" placeholder="Start datum" [max]="today" [(ngModel)]="periodHolder.startDate"
      (ngModelChange)="onStartDateChange()">
    <mat-datepicker-toggle matSuffix [for]="start"></mat-datepicker-toggle>
    <mat-datepicker #start></mat-datepicker>

  </mat-form-field>
  <mat-form-field>
    <input matInput [matDatepicker]="end" placeholder="Eind datum" [max]="today" [(ngModel)]="periodHolder.endDate"
      (ngModelChange)="onEndDateChange()">
    <mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
    <mat-datepicker #end></mat-datepicker>
  </mat-form-field> 


    <!-- <mat-error >Invalid start date</mat-error> -->
  <!-- <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error> -->
