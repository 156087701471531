import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ChartType, ChartOptions } from 'chart.js';
import { SingleDataSet, Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip, Color } from 'ng2-charts';
import { PieChartData } from '../model/pie-chart-data.model';

@Component({
  selector: 'pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit, OnChanges {

  @Input()
  data: PieChartData[];
  @Input()
  refresh: boolean;


  // @Input()
  // total:number;

  // @Input()
  // part:number;
  // Pie
  public pieChartOptions: ChartOptions = {
    legend: { position: 'bottom' },
    responsive: true,
  };
  public pieChartLabels: Label[] = [];
  public pieChartData: SingleDataSet = [];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [];
  public barChartColors: Color[] = [
    { backgroundColor: ['#f1f9ff', '#2699fb'] },

  ];

  constructor() {
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
  }


  ngOnChanges(changes: SimpleChanges): void {
    console.log('chnages');
    this.loadAll();
  }

  ngOnInit() {
    this.loadAll();

  }

  private loadAll() {

    this.pieChartData = [];
    this.pieChartLabels = [];
    if (this.data) {
      for (let i = 0; i < this.data.length; i++) {
        this.pieChartLabels.push(this.data[i].label);
        this.pieChartData.push(this.data[i].value);

      }
    }

  }
}
