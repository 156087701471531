import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartData, ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective, Color, Label, SingleDataSet } from 'ng2-charts';
import { IProvisioningBudget } from 'src/app/shared/model/provisioning-budget.model';
import { PieChartData } from '../../shared/model/pie-chart-data.model';
import { Summary } from '../../../shared/model/summary.model';
import { BudgetSummaryService } from 'src/app/shared/service/budget-summary.service';
import { ProvisioningService } from 'src/app/shared/service/provisioning.service';
import { Formatter } from 'src/app/shared/util/util/formatter';
import { Provisioning } from 'src/app/shared/model/provisioning.model';
import { BudgetSummary } from 'src/app/shared/model/budget-summary.model';
import { BehaviorSubject, forkJoin } from 'rxjs';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {


  @Input() programId: number;
  @Input() budgets: IProvisioningBudget[];

 // summeries: BudgetSummary[] = [];
  provisionings: Provisioning[];

  public chartOptions: ChartOptions = {
    legend: { position: 'bottom' },
    responsive: true,
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        font: {
          size: 20,
        }
      }
    },
    scales: {
      yAxes: [{
        ticks: {
          min: 0
        }
      }]
    },
    animation: {
      onComplete: function () {
        var chartInstance = this.chart,
          ctx = chartInstance.ctx;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'bottom';


        this.data.datasets.forEach(function (dataset, i) {
          var meta = chartInstance.controller.getDatasetMeta(i);
          meta.data.forEach(function (bar, index) {
            let data = Number(dataset.data[index]);//Formatter.euroNL(Number(dataset.data[index]));
            ctx.fillText(data, bar._model.x, bar._model.y);
          });
        });
      }
    },
  };

  public chartEuroOptions: ChartOptions;

  public budgetLabels: Label[] = [];
  totalAmount: BehaviorSubject<number>;
  public paymentsData: SingleDataSet = [];
  totalUnused: BehaviorSubject<number>;
  public unusedData: SingleDataSet = [];
  totalAvailable: BehaviorSubject<number>;
  public availableData: SingleDataSet = [];
  totalWaiting: BehaviorSubject<number>;
  public waitingData: SingleDataSet = [];
  public participantCounts: SingleDataSet = [];
  public budgetManagementEnabled: boolean = false;
  public isReady: boolean = false;


  public chartType: ChartType = 'bar';
  public chartLegend = false;
  public chartPlugins = [];
  // public chartColors: Color[] = [];
  public chartColors: Color[] = [
    { backgroundColor: '#BCE0FD' },
  ];


  constructor(private provisioningService: ProvisioningService,
    private summaryService: BudgetSummaryService) { }

  ngOnInit(): void {
    this.initEuroChartOptions();

    this.totalAmount = new BehaviorSubject(0);
    this.totalWaiting = new BehaviorSubject(0);
    this.totalAvailable = new BehaviorSubject(0);
    this.totalUnused = new BehaviorSubject(0);
    this.programId = Number(localStorage.getItem('programId'));
    this.loadAllData();

    // this.provisioningService.findByProgramId(this.programId).subscribe(async res => {

    //   this.provisionings = res.body;
    //   // this.provisionings.sort((a, b) => a.name.localeCompare(b.name))
    //   //     console.log(this.provisionings);
    //   for (const prov of this.provisionings) {
    //     //  prov.provisioningBudgets.sort((a, b) => a.period.localeCompare(b.period));
    //     for (const budget of prov.provisioningBudgets) {
    //       await this.getAsyncData(budget.id, prov.name + '-' + budget.period);
    //       // this.getData(budget.id, prov.name + '-' + budget.period);

    //     }

    //   }
    //   this.summeries.sort((a, b) => a.name.localeCompare(b.name));
    //   this.createDataSets();

    // });
  }

  loadAllData() {
    this.provisioningService.findByProgramId(this.programId).subscribe(res => {
      var responses=[];
      var summeries: BudgetSummary[] = [];

      this.provisionings = res.body;
      // this.provisionings.sort((a, b) => a.name.localeCompare(b.name))
      //     console.log(this.provisionings);
      for (const prov of this.provisionings) {
        //  prov.provisioningBudgets.sort((a, b) => a.period.localeCompare(b.period));
        for (const budget of prov.provisioningBudgets) {
          responses.push(this.summaryService.getSummary(budget.id))   
        }
      }
      forkJoin(responses).subscribe(([...rsp]) => {
        rsp.forEach( r => {
          var summary: BudgetSummary = r["body"];
          let lastChars = summary.name.substring(0,summary.name.length-4);
         // summary.name = Number.isNaN(Number(lastChars)) ? summary.name.substring(0,summary.name.length-4).replace("_", " ").trim():summary.name.replace("_", " ").trim();
         summary.name = summary.name.replace("_", " ").trim(); 
         summeries.push(summary);
         
        });
        this.isReady = true;
        summeries.sort((a, b) => a.name.localeCompare(b.name));
     //   console.log(summeries);
        this.createDataSets(summeries);
      
      });

    });

  }

  createDataSets(summeries: BudgetSummary[]) {
    for (const summery of summeries) {
 //     console.log(summery);
      this.budgetLabels.push(summery.name);
      this.paymentsData.push(summery.totalPayment);
      this.availableData.push(summery.availableAmount);
      this.totalAvailable.next(this.totalAvailable.value+summery.availableAmount);
      this.waitingData.push(summery.waitingListCount);
      this.totalWaiting.next(this.totalWaiting.value + summery.waitingListCount);
      this.unusedData.push(summery.unusedAmount);
      this.totalUnused.next(this.totalUnused.value+summery.unusedAmount);
      this.participantCounts.push(summery.participantCount);
      this.totalAmount.next(this.totalAmount.value + summery.totalPayment);
      if ( summery.budgetManaged) {
        this.budgetManagementEnabled = true;
      }
    }
  }

  // async getAsyncData(budgetId: number, label: string) {
  //   //   var newResult = this.transactionSums;
  //   let summaryRsp = await this.summaryService.getSummary(budgetId).toPromise();
  //   var summary: BudgetSummary = summaryRsp.body;
  //   summary.name = label;
  //   summary.id = budgetId;
  //   this.summeries.push(summary);

  // }

  format(value: number) {
    return Formatter.euroNL(value);

  }


  initEuroChartOptions() {
    this.chartEuroOptions = JSON.parse(JSON.stringify(this.chartOptions));
    this.chartEuroOptions.animation.onComplete = function () {
      var chartInstance = this.chart,
        ctx = chartInstance.ctx;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'bottom';


      this.data.datasets.forEach(function (dataset, i) {
        var meta = chartInstance.controller.getDatasetMeta(i);
        meta.data.forEach(function (bar, index) {
          let data = Formatter.euroNL(Number(dataset.data[index]));
          ctx.fillText(data, bar._model.x, bar._model.y);
        });
      });
    }

  }
}
