import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MaterialModule} from './material-module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {AppLayoutComponent} from './layout/app-layout.component';
import {AuthGuard} from './auth/auth.guard';
import {CognitoServiceProvider} from './auth/cognito-service';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {AuthInterceptor} from './auth/auth.service';
import {FormsModule} from '@angular/forms';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {PieChartComponent} from './components/shared/pie-chart/pie-chart.component';
import {ChartsModule} from 'ng2-charts';
import {DashboardProvisioningComponent} from './components/dashboard/dashboard.component';
import {DoughnutChartComponent} from './components/shared/doughnut-chart/doughnut-chart.component';
import {BarChartVertComponent} from './components/shared/bar-chart/bar-chart-vert.component';
import {TransactionComponent} from './components/dashboard/transactions/transaction.component';
import {CurrencyPipe, DatePipe} from '@angular/common';
import { SummaryComponent } from './components/dashboard/summary/summary.component';
import { BudgetSelectorComponent } from './components/budget-selector/budget-selector.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { AccessTokensComponent } from './components/dashboard/access-tokens/access-tokens.component';
import { PeriodSelectorComponent } from './components/period-selector/period-selector.component';


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


@NgModule({
  declarations: [
    AppComponent,
    AppLayoutComponent,
    PieChartComponent,
    DoughnutChartComponent,
    DashboardProvisioningComponent,
    BarChartVertComponent,
    TransactionComponent,
    SummaryComponent,
    BudgetSelectorComponent,
    AccessTokensComponent,
    PeriodSelectorComponent
  ],
  imports: [
    BrowserModule,
    ChartsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    FlexLayoutModule,
    MatDatepickerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
  ],
  providers: [    AuthGuard,CurrencyPipe,DatePipe, CognitoServiceProvider,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {floatLabel: 'always'}}],
  bootstrap: [AppComponent]
})
export class AppModule { }
