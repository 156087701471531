<div style="display: block;" class="chart">
  <canvas baseChart
          [data]="doughnutChartData"
          [labels]="doughnutChartLabels"
          [chartType]="doughnutChartType"
          [options]="doughnutChartOptions"
          [plugins]="doughnutChartPlugins"
          [colors]="barChartColors"
          [legend]="doughnutChartLegend">
  </canvas>
</div>
