
<mat-tab-group>
  <mat-tab label="Dashboard">
    <!-- <ng-template matTabContent> -->
      <app-summary [programId]="programId"></app-summary>
    <!-- </ng-template> -->

  </mat-tab>
  <mat-tab label="Transacties">
    <ng-template matTabContent>
      <app-transaction [programId]="programId"></app-transaction>
    </ng-template>
  </mat-tab>

  <mat-tab label="Activaties">
    <ng-template matTabContent>
      <app-access-tokens [programId]="programId"></app-access-tokens>
    </ng-template>
  </mat-tab>

</mat-tab-group>