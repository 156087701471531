import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';

import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { BaseChartDirective, Color, Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip, SingleDataSet } from 'ng2-charts';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import { IProvisioning } from '../../../shared/model/provisioning.model';
import * as moment from 'moment';
import { TransactionService } from '../../../shared/service/transaction.service';
import { ProvisioningService } from '../../../shared/service/provisioning.service';
import { map, switchMap } from 'rxjs/internal/operators';
import { BehaviorSubject } from 'rxjs';
import { Formatter } from 'src/app/shared/util/util/formatter';
import { BudgetSummaryService } from 'src/app/shared/service/budget-summary.service';
import { FormControl, FormGroup } from '@angular/forms';
import { DateHelper } from 'src/app/shared/util/util/date-helper';
import { ProvisioningBudgetService } from 'src/app/shared/service/provisioning-budget.service';
import { IProvider } from 'src/app/shared/model/provider.model';
import { Period } from '../../period-selector/period.model';

const today = new Date();
const month = today.getMonth();
const year = today.getFullYear();

@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.scss']
})
export class TransactionComponent implements OnInit, OnChanges {
  @ViewChild(BaseChartDirective) private chart: BaseChartDirective;
  @Input() programId: string;
  @Input() budget: number;



  title: string;
  total: BehaviorSubject<number>;

  part: any;
  transactionsAllCount = 0;
  result = [];
  lists: any;
  numberOfDays = 0;
  resultToday = -1;
  transctionsToday = -1;
  startDate: Date;
  endDate: Date;
  loaded = false;
  provisionings: IProvisioning[];
  selectedProvider;
  providers: IProvider[];
  isInitialized = false;
  selectedBudgetIds:number[];

  constructor(private summaryService: BudgetSummaryService,
    private transactionService: TransactionService,
    private provisioningService: ProvisioningService,
    public datepipe: DatePipe,
    private provisioningBudgetService: ProvisioningBudgetService) {
  }



  ngOnInit(): void {
    this.total = new BehaviorSubject(0);
    registerLocaleData(localeNl, 'nl');
    this.initProviderList();
  
  }


  loadTransactions() {
    console.log('loading');
    console.log('loading2:' + this.selectedProvider);
    if (!this.isInitialized) return;
  
    var ids: number[] = [];
    if (this.selectedProvider === 0) {
      ids = this.providers.map(p => p.providerId);
    }
    else {
      ids.push(this.selectedProvider);
    }

    this.transactionService.transactionsByBudgetAndProviders(this.selectedBudgetIds,ids,this.startDate, this.endDate).subscribe(res => {
        this.lists = res.body;
        this.createDatalist();
        this.totals();
      });
      // this.summaryService.transactionsByBudgetAndProviders(this.budget, ids, this.startDate, this.endDate).subscribe(res => {
      //   this.lists = res.body;
      //   this.createDatalist();
      //   this.totals();
      // });
  
  }

  initProviderList() {
    this.providers = [];
    this.providers.push({ providerId: 0, partnerName: 'Alle partners' });

  }


  onDateChange(period: Period) {
    console.log("period:" + period);
    this.startDate = period.startDate;
    this.endDate = period.endDate;

    this.loadTransactions();
  }

  ngOnChanges() {

    this.numberOfDays = 0;

  }
  onProviderChange(selected) {
    console.log('provider cvhange');
    this.loadTransactions();
  }

  // onBudgetChange(budgetId: number) {
  //   this.budget = budgetId;
  //   this.getProvidersWithTransactions(budgetId);
  // }

  onBudgetsChange(ids: number[]) {
    console.log("selected budgets:" +ids);
    this.selectedBudgetIds = ids;
    this.getProvidersWithTransactions();
   // this.budget = budgetId;
   // this.getProvidersWithTransactions(budgetId);
  }

  totals() {
    this.total.next(this.lists
      .map(tran => tran[1])
      .reduce((acc, qty) => acc + qty, 0));

    this.transactionsAllCount = this.lists
      .map(tran => tran[2])
      .reduce((acc, qty) => acc + qty, 0);

  }

  getProvidersWithTransactions() {
    this.provisioningBudgetService.findProvidersWithAnyTransaction(this.selectedBudgetIds).subscribe(
      res => {
        console.log("get providers");
        res.body.sort((a, b) => a.partnerName.localeCompare(b.partnerName));

        this.initProviderList();
        this.providers = this.providers.concat(res.body);
        if (this.providers.filter(prov => prov.providerId === this.selectedProvider).length <= 0) {
          // provider not found so reset to all partners
          this.selectedProvider = 0;
        }
        this.isInitialized = true;
        this.loadTransactions();
      });
  }

  createDatalist() {
    const newResult = [];
    var dayCounter: number;
    var totalDays: number;
    var current: Date;
    current = this.startDate;
    totalDays = DateHelper.calculateDiff(this.startDate, this.endDate);

    for (dayCounter = 0; dayCounter <= totalDays; dayCounter++) {
      const res = this.lists.filter(a => {

        return a[0] === this.datepipe.transform(current, 'yyyy-MM-dd');
      });
      if (res.length > 0) {
        newResult.push({ label: this.datepipe.transform(current, 'yyyy-MM-dd'), qty: res[0][1], count: res[0][2] });
      } else {
        newResult.push({ label: this.datepipe.transform(current, 'yyyy-MM-dd'), qty: 0, count: 0 });
      }
      // its is new day
      current = DateHelper.addDays(current, 1);

    }
    this.result.length = 0;
    this.result = newResult;

  }

  format(value: number) {
    return Formatter.euroNL(value);
  }

}
