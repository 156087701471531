import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { IProvisioningBudget } from 'src/app/shared/model/provisioning-budget.model';
import { IProvisioning, Provisioning } from 'src/app/shared/model/provisioning.model';
import { ProvisioningService } from 'src/app/shared/service/provisioning.service';

@Component({
  selector: 'app-budget-selector',
  templateUrl: './budget-selector.component.html',
  styleUrls: ['./budget-selector.component.scss']
})
export class BudgetSelectorComponent implements OnInit {
  programId: number;
  provisionings: IProvisioning[];
  defaultProvisioning: number;
  budgets: IProvisioningBudget[];
  defaultBudget: number;
  @Output() selectedBudget = new EventEmitter<number>();
  @Output() selectedBudgets = new EventEmitter<number[]>();
  constructor(private provisioningService: ProvisioningService) { }

  ngOnInit(): void {
    this.programId = Number(localStorage.getItem('programId'));
    this.provisioningService.findByProgramId(this.programId).subscribe(res => {
      this.initProvisioningsList();
      for (var prov of res.body.sort((a, b) => a.name.localeCompare(b.name))) {
        this.provisionings.push(prov);
      }

      this.defaultProvisioning = this.provisionings[0].id;
      this.selectAllBudgets();
      // this.getBudget(this.provisionings);
    });
  }

  initProvisioningsList() {
    this.provisionings = [];
    this.provisionings.push({ id: 0, name: 'Alle voorzieningen' });
  }

  private selectAllBudgets() {
    var ids: number[] = [];
    this.provisionings.forEach(prov => {
      console.log(prov);
      if (prov.id > 0) {
        prov.provisioningBudgets.forEach(bud => {
          ids.push(bud.id);
        })
      }
    })
    this.selectedBudgets.emit(ids);

  }

  private getBudget(provisionings) {
    this.budgets = provisionings[0].provisioningBudgets.sort((a, b) => a.period.localeCompare(b.period)).reverse();
    this.defaultBudget = this.budgets[0].id;
    localStorage.setItem('budget', this.defaultBudget.toString());
    // console.log(this.selectedBudget);
    this.selectedBudget.emit(this.budgets[0].id);
  }

  onProvisioningChange(provisioning) {
    if (provisioning.value > 0) {
      const prov = this.provisionings.filter(provi => provi.id === provisioning.value);
      if (prov.length > 0) {
        // this.selectedBudget.emit(this.budgets[0].id);
        this.budgets = prov[0].provisioningBudgets.sort((a, b) => a.period.localeCompare(b.period)).reverse();
        this.defaultBudget = this.budgets[0].id;
        localStorage.setItem('budget', this.defaultBudget.toString());
        console.log("selector" + this.defaultBudget);
        this.emitNewBudgetId(this.budgets[0].id);
      //  this.selectedBudget.emit(this.budgets[0].id);
      }
    }
    else {
      console.log('Alle budgeten');
      this.selectAllBudgets();
    }
  }

  onBudgetChange(budget) {
    console.log("changje");
    this.emitNewBudgetId(budget.value);
    //this.defaultBudget = budget;
    //this.selectedBudget.emit(budget.value);
    // console.log(this.defaultBudget);
  }

  emitNewBudgetId(id:number){
    var ids:number[]=[];
    ids.push(id);
    this.selectedBudgets.emit(ids);
  }
}
