/* tslint:disable */
import { Injectable } from '@angular/core';
import * as AWSCognito from 'amazon-cognito-identity-js';
import {CognitoRefreshToken} from 'amazon-cognito-identity-js';
import {environment} from '../../environments/environment';
export interface CognitoCallback {
    cognitoCallback(message: string, result: any): void;
}
export interface LoggedInCallback {
    isLoggedIn(message: string, loggedIn: boolean): void;
}
export interface Callback {
    callback(): void;
    callbackWithParam(result: any): void;
}

@Injectable()
export class CognitoServiceProvider {
  POOL_DATA = {
    UserPoolId: environment.UserPoolId,
    ClientId: environment.ClientId
  };

    getUserPool() {
        return new AWSCognito.CognitoUserPool(this.POOL_DATA);
    }
    getCurrentUser() {
        return this.getUserPool().getCurrentUser();
    }



  signUp(userData) {
    return new Promise((resolved, reject) => {
      const userPoolData = new AWSCognito.CognitoUserPool(this.POOL_DATA);

      const userAttribute = [];
      userAttribute.push(
        new AWSCognito.CognitoUserAttribute({ Name: 'email', Value: userData.email })
      );
      userPoolData.signUp(userData.email, userData.password, userAttribute, null, function (err, result) {
        if (err) {
          reject(err);
        } else {
          resolved(result);
        }
      });
    });
  }

  confirmUser(verificationCode, userName) {
    return new Promise((resolved, reject) => {
      const userPoolData = new AWSCognito.CognitoUserPool(this.POOL_DATA);
      const cognitoUser = new AWSCognito.CognitoUser({
        Username: userName,
        Pool: userPoolData
      });
      cognitoUser.confirmRegistration(verificationCode, true, function (err, result) {
        if (err) {
          reject(err);
        } else {
          resolved(result);
        }
      });
    });
  }
    getAccessToken(callback: Callback): void {
        if (callback == null) {
            throw('CognitoUtil: callback in getAccessToken is null...returning');
        }
        if (this.getCurrentUser() !== null)
            this.getCurrentUser().getSession(function (err, session) {
                if (err) {
                    console.log('CognitoUtil: Can\'t set the credentials:' + err);
                    callback.callbackWithParam(null);
                } else {
                    if (session.isValid()) {
                        callback.callbackWithParam(session.getAccessToken().getJwtToken());
                    }
                }
            });
        else {
            callback.callbackWithParam(null);
        }
    }

    signOut() {
      if (this.getCurrentUser() != null) {
        this.getCurrentUser().signOut();
      }
      localStorage.removeItem('accessToken');
      localStorage.removeItem('program');
      localStorage.removeItem('refreshToken');
    }
  
    getIdToken(callback: Callback): void {
        if (callback == null) {
            throw('CognitoUtil: callback in getIdToken is null...returning');
        }
        if (this.getCurrentUser() != null)
            this.getCurrentUser().getSession(function(err, session) {
                if (err) {
                    console.log('CognitoUtil: Can\'t set the credentials:' + err);
                    callback.callbackWithParam(null);
                } else {
                    if (session.isValid()) {
                        callback.callbackWithParam(session.getIdToken().getJwtToken());
                    } else {
                        console.log('CognitoUtil: Got the id token, but the session isn\'t valid');
                    }
                }
            });
        else {
          callback.callbackWithParam(null);
        }
    }

  refreshToken(){
    const userPoolData = new AWSCognito.CognitoUserPool(this.POOL_DATA);
    const cognitoUser = userPoolData.getCurrentUser();
    if (cognitoUser != null) {
      cognitoUser.getSession((err, session) =>{
        const refresh_token = session.getRefreshToken();
        cognitoUser.refreshSession(refresh_token, (refErr, refSession) => {
          if (refErr) {
            throw refErr;
          }
          else{
            localStorage.setItem('accessToken', refSession.accessToken.jwtToken);
            localStorage.setItem('refreshToken',refSession.refreshToken.token);
            window.location.reload();
          }
        });
      })
    }
    else {
      this.signOut();
    }
  }

  getTokens(session) {
    return {
      accessToken: session.getAccessToken().getJwtToken(),
      idToken: session.getIdToken().getJwtToken(),
      refreshToken: session.getRefreshToken().getToken()
    };
  };

    getRefreshToken(callback: Callback): void {
        if (callback == null) {
            throw('CognitoUtil: callback in getRefreshToken is null...returning');
        }
        if (this.getCurrentUser() != null)
            this.getCurrentUser().getSession(function(err, session) {
                if (err) {
                    console.log('CognitoUtil: Can\'t set the credentials:' + err);
                    callback.callbackWithParam(null);
                } else {
                    if (session.isValid()) {
                        callback.callbackWithParam(session.getRefreshToken());
                    }
                }
            });
        else {
          callback.callbackWithParam(null);
        }
    }

  authenticate(userData) {
    return new Promise((resolved, reject) => {
      const userPoolData = new AWSCognito.CognitoUserPool(this.POOL_DATA);
      const authDetails = new AWSCognito.AuthenticationDetails({
        Username: userData.email,
        Password: userData.password
      });
      const cognitoUser = new AWSCognito.CognitoUser({
        Username: userData.email,
        Pool: userPoolData
      });
      cognitoUser.authenticateUser(authDetails, {
        onSuccess: result => {
          resolved(result);
        },
        onFailure: err => {
          reject(err);
        },
        newPasswordRequired: userAttributes => {
          userAttributes.email = userData.email;
          delete userAttributes.email_verified;
          cognitoUser.completeNewPasswordChallenge(userData.password, userAttributes, {
            onSuccess: function(result) { },
            onFailure: function(error) {
              reject(error);
            }
          });
        }
      });
    });
  }
  forgotPassword(userData) {
    return new Promise((resolve, reject) => {
      const userPoolData = new AWSCognito.CognitoUserPool(this.POOL_DATA);
      const cognitoUser = new AWSCognito.CognitoUser({
        Username: userData.email,
        Pool: userPoolData
      });
      cognitoUser.forgotPassword({
        onSuccess: result => {
          resolve(result);
        },
        onFailure: err => {
          reject(err);
        }
      });
    });
  }

  confirmPassword(verificationCode, newPassword, username) {

    const promise = new Promise((resolve, reject) => {
      const userPoolData = new AWSCognito.CognitoUserPool(this.POOL_DATA);
      const cognitoUser = new AWSCognito.CognitoUser({
        Username: username,
        Pool: userPoolData
      });
      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onFailure: (err) => {
          console.log("onFailure:" + err);
          reject(err);
        },
        onSuccess: () => {
          console.log("onSuccess:");
          resolve({});
        }
      });
    });
    return promise;
  }

}
