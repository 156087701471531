import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { ChartType, ChartOptions } from 'chart.js';
import {
  SingleDataSet, Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip, Color,
  PluginServiceGlobalRegistrationAndOptions, BaseChartDirective
} from 'ng2-charts';

@Component({
  selector: 'doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: [ './doughnut-chart.component.scss' ]
})
export class DoughnutChartComponent  {

  @Input()
  total:number;

  @Input()
  part:number;
  // Pie
  public doughnutChartOptions: ChartOptions = {
    legend: {position: 'bottom'},
    responsive: true,
    animation: {
      onComplete: function (animation) {
        this.modifyCenterText();
      }.bind(this)
    }
  };
  public doughnutChartLabels: Label[] = ['Rest','Geactiveerd'];
  public doughnutChartData: SingleDataSet = [];
  public doughnutChartType: ChartType = 'doughnut';
  public doughnutChartLegend = true;
  @ViewChild(BaseChartDirective, { static: true })
  chartElem: BaseChartDirective;

  public chartOptions: ChartOptions = {
    animation: {
      onComplete: function(animation) {
        this.modifyCenterText();
      }.bind(this)
    }
  };
  public doughnutChartPlugins: PluginServiceGlobalRegistrationAndOptions[] = [{
  }];
  public barChartColors: Color[] = [
    {   backgroundColor: ['#f1f9ff','#2699fb' ]},

  ];

  constructor() {
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
  }

  ngOnInit() {
    this.doughnutChartData.push(this.total);
      this.doughnutChartData.push(this.part);
  }

  modifyCenterText() {
    const chart = this.chartElem.chart;
    const ctx = chart.ctx;
    const txt = this.part.toString();

    //Get options from the center object in options
    const sidePadding = 60;
    const sidePaddingCalculated =
      (sidePadding / 100) * (chart["innerRadius"] * 2);
    console.log(chart);
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
    const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;

    //Get the width of the string and also the width of the element minus 10 to give it 5px side padding
    const stringWidth = ctx.measureText(txt).width;
    const elementWidth = chart["innerRadius"] * 2 - sidePaddingCalculated;

    // Find out how much the font can grow in width.
    const widthRatio = elementWidth / stringWidth;
    const newFontSize = Math.floor(30 * widthRatio);
    const elementHeight = chart["innerRadius"] * 2;

    // Pick a new font size so it will not be larger than the height of label.
    const fontSizeToUse = Math.min(newFontSize, elementHeight);

    // ctx.beginPath();
    // ctx.arc(centerX, centerY, chart["innerRadius"], 0, 2 * Math.PI, false);
    // ctx.fillStyle = "white";
    // ctx.fill();

    ctx.font = fontSizeToUse + "px Arial";
    ctx.fillStyle = "blue";

    // Draw text in center
    ctx.fillText(txt, centerX, centerY);
  }

}
