import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class PartProvBudgetWalletService {
  public resourceUrl = environment.SERVER_API_URL + 'api/part-prov-budget-wallets';

  constructor(protected http: HttpClient) {
  }

  countAllByDateBudgetId(budgetId: number) {
    return this.http.get(`${this.resourceUrl}-date-count/${budgetId}`, );
  }
}
