import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {CognitoServiceProvider} from './cognito-service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private router: Router, private cognitoService: CognitoServiceProvider) { }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (req.url.indexOf('assets/i18n/') > 1) {
            return next.handle(req.clone());
        }
        if (req.headers.get('No-Auth') === 'True') {
            return next.handle(req.clone());
        }
        if (localStorage.getItem('accessToken') != null) {
            const clonedreq = req.clone({
                headers: req.headers.set(
                    'Authorization',
                    'Bearer ' + localStorage.getItem('accessToken')
                )
            });
            return next.handle(clonedreq).pipe(
                tap(
                    succ => {

                    },
                    err => {
                        if (err.status === 401) {
                           this.cognitoService.refreshToken();
                        } else if ((err.status === 403)) {
                           localStorage.removeItem("accessToken");
                          localStorage.removeItem("refreshToken");
                          sessionStorage.clear();
                          this.router.navigateByUrl('/login');
                        }
                    }
                )
            );
        } else {
            this.router.navigateByUrl('/login');
        }
    }
}
