<div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="5px" fxLayoutAlign="center">
  <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px" fxLayoutAlign="center">
    <!-- <app-budget-selector (selectedBudget)="onBudgetChange($event)"></app-budget-selector> -->
    <div style="margin:10px!important;">
      <mat-form-field>
        <mat-select [(ngModel)]="selectedProvider" (selectionChange)="onProviderChange($event)">
          <mat-option *ngFor="let provider of providers" [value]="provider.providerId">
            {{provider.partnerName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <app-budget-selector  (selectedBudgets)="onBudgetsChange($event)"></app-budget-selector>
  </div>
  <app-period-selector (selectedPeriod)="onDateChange($event)"></app-period-selector>

</div>
<div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px ">

  <div fxFlex.xl="calc(20% - 20px)" fxFlex.lg="calc(20% - 20px)" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
    <mat-card style="font-size: 14px !important; margin: 10px !important;">
      <div class="title">Totaal bedrag van de selectie</div>
      <div style=" text-align: center;">
        <span class="amount">{{format(total|async)}} </span>
      </div>
    </mat-card>
  </div>

  <div fxFlex.xl="calc(20% - 20px)" fxFlex.lg="calc(20% - 20px)" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
    <mat-card style="font-size: 14px !important; margin: 10px !important;  ">
      <div class="title">Aantal transacties van de selectie</div>
      <div style=" text-align: center;">
        <span class="amount">{{transactionsAllCount}}</span>
      </div>
    </mat-card>
  </div>

</div>

<div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px">
  <div fxFlex.xl="calc(100% - 20px)" fxFlex.lg="calc(100% - 20px)" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
    <mat-card style="font-size: 14px !important; margin: 10px !important;">
      <bar-chart-vert [title]="'Bedrag per dag'" [result]="result" [legend]="true" *ngIf="result.length > 0">
      </bar-chart-vert>
      <br><br>
    </mat-card>
  </div>
</div>