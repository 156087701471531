import { DatePipe } from "@angular/common";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({providedIn: 'root'})
export class AccessTokenStatsService {
  public resourceUrl = environment.SERVER_API_URL + 'api/dashboard/v1/access-tokens';

  constructor(protected http: HttpClient, public datepipe: DatePipe) {
  }

   accessTokensDailyStats(programId: string,start: Date, end: Date) {
    let params = new HttpParams();
    params = params.append("programId", programId).append("startDate", this.datepipe.transform(start, 'yyyy-MM-dd')).append("endDate", this.datepipe.transform(end, 'yyyy-MM-dd'));
    return this.http.get(`${this.resourceUrl}/activations`, {params: params, observe : 'response'});
  }



}
