import {Component, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {ChartOptions, ChartType, ChartDataSets} from 'chart.js';
import {BaseChartDirective, Color, Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip} from 'ng2-charts';
import { Formatter } from 'src/app/shared/util/util/formatter';

@Component({
  selector: 'bar-chart-vert',
  templateUrl: './bar-chart-vert.component.html',
  styleUrls: ['./bar-chart-vert.component.scss']
})
export class BarChartVertComponent implements OnInit, OnChanges {
  @ViewChild(BaseChartDirective) private chart: BaseChartDirective;
  @Input()
  result: any;
  @Input()
  datasets: ChartDataSets[];
  @Input()
  labels:Label[];



  // @Input()
  // scale: number;

  // @Input()
  // step: number;

  @Input()
  title: string;

  @Input()
  legend: boolean;
  ready: boolean = false;
  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        font: {
          size: 20,
        }
      }
     }
    // animation: {
    //   onComplete: function () {
    //     var chartInstance = this.chart,
    //       ctx = chartInstance.ctx;
    //     ctx.textAlign = 'center';
    //     ctx.textBaseline = 'bottom';
    //     this.data.datasets.forEach(function (dataset, i) {
    //       var meta = chartInstance.controller.getDatasetMeta(i);
    //       meta.data.forEach(function (bar, index) {
    //         let data = Formatter.euroNL(Number(dataset.data[index]));
    //         ctx.fillText(data, bar._model.x, bar._model.y - 5);
    //       });
    //     });
    //   }
    // },
  };
 

  // public barChartOptions: ChartOptions
  //  = {
  //   responsive: true,
  //   plugins: {
  //     datalabels: {
  //       anchor: 'end',
  //       align: 'end',
  //       font: {
  //         size: 20,
  //       }
  //     }
  //   },
  //   animation: {
  //     onComplete: function () {
  //       var chartInstance = this.chart,
  //         ctx = chartInstance.ctx;
  //       ctx.textAlign = 'center';
  //       ctx.textBaseline = 'bottom';
  //       this.data.datasets.forEach(function (dataset, i) {
  //         var meta = chartInstance.controller.getDatasetMeta(i);
  //         meta.data.forEach(function (bar, index) {
  //           let data = Formatter.euroNL(Number(dataset.data[index]));
  //           ctx.fillText(data, bar._model.x, bar._model.y - 5);
  //         });
  //       });
  //     }
  //   },
  //   scales: {
  //     yAxes: [{
  //       ticks: {
  //         min: 0,
  //         // max: this.scale,
  //         // stepSize: this.step
  //       }
  //     }]
  //   },

  // };
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend;
  public barChartPlugins = [];

  public barChartData: ChartDataSets[] = [];

  public barChartColors: Color[] = [
    {backgroundColor: '#BCE0FD'},
  ];

  constructor() {
  }

  ngOnInit() {
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
  //  this.barChartLabels.length = 0;
    // const val = [];
    // this.loadAll(val);
    this.barChartLegend = this.legend;

    // this.barChartOptions = {
    //   responsive: true,
    //   animation: {
    //     onComplete: function() {
    //       var chartInstance = this.chart,
    //         ctx = chartInstance.ctx;
    //       ctx.textAlign = 'center';
    //       ctx.textBaseline = 'bottom';
    //       this.data.datasets.forEach(function(dataset, i) {
    //         var meta = chartInstance.controller.getDatasetMeta(i);
    //         meta.data.forEach(function(bar, index) {
    //           let data = Number(dataset.data[index]);
    //           ctx.fillText(data, bar._model.x, bar._model.y - 5);
    //         });
    //       });
    //     }
    //   },
    //   scales: {
    //     yAxes: [{
    //       ticks: {
    //         min: 0,
    //       //  max: this.scale,
    //     //    stepSize: this.step
    //       }
    //     }]
    //   },
  
    // };
  }



  private loadAll(val: any[]) {
    console.log('loading');
   this.barChartLabels.length = 0;
   this.barChartData = [];
    // this.barChartLabels = this.labels;
    // this.barChartData = this.datasets;
    // console.log(this.labels);
    // console.log(this.datasets);
    for (let i = 0; i < this.result.length; i++) {
      this.barChartLabels.push(this.result[i].label);
      val.push(this.result[i].qty);
    }
    this.barChartData.push({data: val, label: this.title});
  //  this.chart.update();
  this.ready = true;
    
  }



  ngOnChanges() {
    this.loadAll([]);
   // this.chart.update();
  }
}
