import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

import {map, switchMap} from 'rxjs/internal/operators';

import {HttpResponse} from '@angular/common/http';

import {registerLocaleData} from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import {PartProvBudgetWalletService} from '../../shared/service/part-prov-budget-wallet.service';
import {IProvisioning, Provisioning} from '../../shared/model/provisioning.model';

import {TransactionService} from '../../shared/service/transaction.service';
import {ProvisioningService} from '../../shared/service/provisioning.service';
import {IProvisioningBudget} from '../../shared/model/provisioning-budget.model';

@Component({
  selector: 'app-dashboard-provisioning',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush    
})

export class DashboardProvisioningComponent implements OnInit {

  result = [];
  lists: any;
  programId: number;
  provisionings: Provisioning[];
  defaultProvioning: number;
  budgets: IProvisioningBudget[];
  defaultBudget: number;

  constructor(
    private partProvBudgetWalletService: PartProvBudgetWalletService,
    private provisioningService: ProvisioningService) {
  }

  ngOnInit(): void {
    registerLocaleData(localeNl, 'nl');
    this.programId = Number(localStorage.getItem('programId'));
    this.provisioningService.findByProgramId(this.programId).subscribe(res => {
      this.provisionings = res.body;
      this.defaultProvioning = this.provisionings[0].id;
      this.getBudget(this.provisionings);
    });
  }

  private getBudget(provisionings) {
    this.budgets = provisionings[0].provisioningBudgets;
    this.defaultBudget = this.budgets[0].id;
    localStorage.setItem('budget', this.defaultBudget.toString());
  }

  changedProv(provisioning) {
    const prov = this.provisionings.filter(provi => provi.id === provisioning.value);
    if ( prov.length > 0) {
      this.getBudget(prov);
    }
  }

}
