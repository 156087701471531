import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import { BudgetSummary } from '../model/budget-summary.model';
import { DatePipe } from '@angular/common';

@Injectable({providedIn: 'root'})
export class BudgetSummaryService {
  public resourceUrl = environment.SERVER_API_URL + 'api/dashboard/v1/provisioning-budgets';

  constructor(protected http: HttpClient, public datepipe: DatePipe) {
  }

  getSummary(id: number): Observable<HttpResponse<BudgetSummary>> {
    return this.http.get<BudgetSummary>(`${this.resourceUrl}/${id}/summary`, {observe: 'response'});
  }

  // transactionsByProvider(budgetId: number, providerId: number, start: string, end: string ) {
  //   let params = new HttpParams();
  //   params = params.append("startDate", start).append("endDate", end);
  //   return this.http.get(`${this.resourceUrl}/${budgetId}/partners/${providerId}/transactions`, {params: params, observe : 'response'});
  // }

  transactionsByBudgetAndProviders(budgetId: number, providers:number[],start: Date, end: Date) {
    let params = new HttpParams();
    params = params.append("startDate", this.datepipe.transform(start, 'yyyy-MM-dd')).append("endDate", this.datepipe.transform(end, 'yyyy-MM-dd'))
              .append("providerIds", providers.toString() );
    return this.http.get(`${this.resourceUrl}/${budgetId}/transactions`, {params: params, observe : 'response'});
  }



}
